/*---------------------------
	Fonts
----------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');


// fonts
$base-font-size: 15;
$base-font: 'Inter', sans-serif;
$heading-font: 'Inter', sans-serif;


// color
$dark-gray: #202020;
$body-color: #D8D8D8;
$white: #fff;
$light: #D8D8D8;
$black: #000;
$small-black: #333;
$cyan: #848892;

$theme-primary-color: #59c277;
$theme-primary-color-s2: #FFE600;
$theme-primary-color-s3: #ba5c58;
$bg-color:#131313;
$bg-color2:#222121;
$bg-color3:#191919;
$body-bg-color: #fff;
$section-bg-color: #f3f8ff;
$text-color: #585858;
$text-light-color: #7b7b7b;
$heading-color: $white;
$border-color: #3e3b3b;
$border-color-s2: #e4ebf2;